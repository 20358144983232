import config from '../config.json';

class Media {
    constructor(attributes) {
        this.name = attributes.name;
        this.altText = attributes.altText;
        this.caption = attributes.caption;
        this.mime = attributes.mime;
        this.url = `${config.url}${attributes.url}`;
        this.hasOtherFormats = !!attributes.formats;
        this.thumbnailUrl = this.hasOtherFormats ? `${config.url}${attributes.formats.thumbnail.url}` : null;
        this.largeUrl = this.hasOtherFormats ? `${config.url}${attributes.formats.large.url}` : null;
        this.mediumUrl = this.hasOtherFormats ? `${config.url}${attributes.formats.medium.url}` : null;
        this.smallUrl = this.hasOtherFormats ? `${config.url}${attributes.formats.small.url}` : null;
    }

    isImage() {
        return this.mime.includes("image");
    }

    isVideo() {
        return this.mime.includes("video");
    }
}

export default Media;