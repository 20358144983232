import config from '../config.json';
import Media from './Media';

class LocalisedCollectionItem {
    constructor({ id, locale, title, label, description, item, media, faIcon }) {
        this.id = id;
        this.locale = locale;
        this.title = title || label;
        this.description = description || item;
        this.icon = faIcon;
        this.media = !!media ? media.data.map(item => new Media(item.attributes)) : [];
    }
}

class CollectionItem {
    constructor(attributes) {
        this.id = attributes.id;
        this.lang = {
            [attributes.locale]: new LocalisedCollectionItem(attributes),
        };
        attributes.localizations.data.forEach((localization => {
            this.lang[localization.attributes.locale] = new LocalisedCollectionItem({
                ...localization.attributes,
                "media": attributes.media
            });
        }));
    }

    in(language) {
        return this.lang[language] || Object.values(this.lang)[0];
    }
}

export {
    LocalisedCollectionItem,
    CollectionItem
};