import "react-responsive-carousel/lib/styles/carousel.min.css";

import './GalleryItem.css';
import Slideshow from '../Slideshow/Slideshow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function GalleryItem({ className, id, title, description, media }) {
    return (<div className={`collection-item ${className||""}`}>
        <Slideshow media={media} formatToUse="url" showArrows={false} />
        <div className='item-title'>
            <div>{title}</div>
            <Link to={`/projects/${id}`}><FontAwesomeIcon className='circle' icon="fa-solid fa-ellipsis" /></Link>
        </div>
    </div>);
}

export default GalleryItem;
