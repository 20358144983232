import React, { useContext, useState } from 'react';
import {
    Link,
    Outlet
} from "react-router-dom";

import './Layout.css';
import 'react-responsive-carousel/lib/styles/carousel.css';
import Copyright from '../../components/Copyright/Copyright';
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';
import Email from '../../components/Email/Email';
import Socials from '../../components/Socials/Socials';
import 'flag-icons/css/flag-icons.min.css';

const country = {
    "en": "gb"
};

function Layout() {
    const { languages, language, setLanguage, pages, websiteInfo, socials } = useContext(WebsiteInfoContext);
    const [selectedTab, setSelectedTab] = useState(window.location.pathname.split('/')[1] || null);

    const checkSelection = (name) => selectedTab === name ? 'selected' : '';
    const switchLanguage = (lang) => {
        setLanguage(lang);
    };

    return (
        <div className="Layout">
            <header className="header">
                <Link className='logo' to="/" onClick={() => setSelectedTab(null)}>
                    <img alt='Logo' src={websiteInfo.in(language).logoSmall.url} />
                </Link>
                <div>
                    <ul className="nav">
                        {pages.slice(1).map(page => <li key={`link-${page.link}`}>
                            <Link to={`/${page.link}`} onClick={() => setSelectedTab(page.link)} className={checkSelection(page.link)}>
                                {page.in(language).title}
                            </Link>
                        </li>)}
                    </ul>
                    <div className="language-select">
                        {languages.map(lang => <a href={`#${lang}`} className={`language-select-item ${language === lang ? 'selected' : ''}`} key={`lang-${lang}`} onClick={() => switchLanguage(lang)}>
                            <span className={`fi fi-${country[lang] || lang}`}></span>
                        </a>)}
                    </div>
                </div>
            </header>
            <div className="content">
                <Outlet />
            </div>
            <footer className="footer">
                <Copyright owner={websiteInfo.in(language).copyright} startYear={websiteInfo.in(language).copyrightYear} />
                <Email address={websiteInfo.in(language).mainContact} />
                <Socials socials={socials} />
            </footer>
        </div>
    );
}

export default Layout;
