import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Socials.css'

function Socials({ className, socials }) {
    return (<div className={`socials ${className || ""}`}>
        {socials.map(social => <a key={uuid()} href={social.url}><FontAwesomeIcon icon={social.faIcon} /></a>)}
    </div>);
}

export default Socials;
