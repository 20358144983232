import Media from './Media';

class LocalisedWebsiteInfo {
    constructor({ locale, websiteTitle, websiteSubtitle, mainContact, copyright, copyrightYear, logoSmall, logoBig }) {
        this.locale = locale;
        this.title = websiteTitle;
        this.subtitle = websiteSubtitle;
        this.mainContact = mainContact;
        this.copyright = copyright;
        this.copyrightYear = copyrightYear;
        this.logoSmall = new Media(logoSmall.data.attributes);
        this.logoBig = new Media(logoBig.data.attributes);
    }
}

class WebsiteInfo {
    constructor(attributes) {
        this.lang = {
            [attributes.locale]: new LocalisedWebsiteInfo(attributes),
        };
        attributes.localizations.data.forEach((localization => {
            this.lang[localization.attributes.locale] = new LocalisedWebsiteInfo({
                ...localization.attributes,
                "logoSmall": attributes.logoSmall,
                "logoBig": attributes.logoBig
            });
        }));
    }

    in(language) {
        return this.lang[language] || Object.values(this.lang)[0];
    }
}

export {
    LocalisedWebsiteInfo,
    WebsiteInfo
};