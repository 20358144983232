import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ListItem.css';

function ListItem({ className, title, description, icon }) {
    return (<li className={className} style={{ listStyle: icon ? "none" : "disc", marginLeft: icon ? "-21px" : "0" }}>
    {!icon ? '' : <FontAwesomeIcon icon={icon} />} {!title ? '' : <b>{title}:</b>} {description}
    </li>);
}

export default ListItem;
