import { v4 as uuid } from 'uuid';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './Slideshow.css';

function Slideshow({ media, formatToUse, showArrows, interval, width, height, className }) {
    return (<div className={`slideshow-slides-holder ${className||""}`} style={{ width: width || null, height: height || null }}>
        <Carousel className="slideshow-slides" showArrows={showArrows === undefined ? true : showArrows} infiniteLoop={true} showStatus={false} showThumbs={false} autoPlay={true} dynamicHeight={false} interval={interval || 5000}>
            {(media || []).map(item =>
                <div className='slideshow-slide' style={{ width: width || null, height: height || null }} key={uuid()}>
                    {item.isImage() ? <img src={item[formatToUse || "url"]} alt={item.altText}/> :
                    (item.isVideo() ? <video controls autoPlay muted><source src={item.url} type={item.mime} />This browser doesn't support {item.mime}?</video> :
                    `Format ${item.mime} unsupported.`)}
                </div>
            )}
        </Carousel>
    </div>);
}

export default Slideshow;
