import config from '../config.json';

const get = async (url) => {
    const headers = {
        'Authorization': `Bearer ${config.apiToken}`
    }
    return await fetch(url, {headers}).then(r => r.json());
}

export {
    get
}