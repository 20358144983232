import React from 'react';

const WebsiteInfoContext = React.createContext({
    websiteInfo: {},
    setWebsiteInfo: () => {},

    pages: [],
    setPages: ()=>{},

    languages: ["en"],
    setLanguages: () => {},

    language: "en",
    setLanguage: () => {},
    
    socials: [],
    setSocials: ()=>{},
});

export default WebsiteInfoContext;