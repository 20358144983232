import { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { CollectionItem } from '../../model/CollectionItem';
import './CollectionPage.css';
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';
import GalleryItem from '../../components/GalleryItem/GalleryItem';
import { get } from '../../utils/fetch-utils';
import FeedItem from '../../components/FeedItem/FeedItem';
import ListItem from '../../components/ListItem/ListItem';

function CollectionPage({ title, collectionUrl, collectionStyle }) {
    const { language, websiteInfo } = useContext(WebsiteInfoContext);
    const [collection, setCollection] = useState([]);
    useEffect(() => {
        document.title = `${websiteInfo.in(language).title} - ${title}`;
    }, [title, language, websiteInfo]);

    useEffect(() => {
        const getData = async () => {
            const collectionInformation = await get(`${collectionUrl}`);
            const _collection = collectionInformation.data.map(collectionItem => new CollectionItem({"id": collectionItem.id, ...collectionItem.attributes}));
            setCollection([..._collection].sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)));
        };
        getData();
    }, [collectionUrl]);

    if (collectionStyle === "gallery") {
        return (
            <div className={`collection ${collectionStyle}`}>
                {collection.map(item => <GalleryItem key={uuid()} {...item.in(language)} />)}
            </div>
        );
    }

    if (collectionStyle === "feed") {
        return (
            <div className={`collection ${collectionStyle}`}>
                {collection.map(item => <FeedItem key={uuid()} {...item.in(language)} />)}
            </div>
        );
    }

    if (collectionStyle === "list") {
        return (
            <div className={`collection ${collectionStyle}`}>
                <ul>
                    {collection.map(item => <ListItem key={uuid()} {...item.in(language)} />)}
                </ul>
            </div>
        );
    }

    return (<p>This list has no <code>dynamicCollectionStyle</code> defined.</p>);
}

export default CollectionPage;
