import React, { useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { v4 as uuid } from 'uuid';
import { Carousel } from 'react-responsive-carousel';

import './StaticPage.css';
import rehypeRaw from 'rehype-raw';
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';

function StaticPage({ title, content, media }) {
    const { language, websiteInfo } = useContext(WebsiteInfoContext);
    useEffect(() => {
        document.title = `${websiteInfo.in(language).title} - ${title}`;
    }, [title, language, websiteInfo]);
    return (
        <div className='StaticPage'>
            {/* {title} */}
            <Carousel showArrows={true} showStatus={false} showThumbs={false} autoplay={true}>
                {(media || []).map(item =>
                    <div key={uuid()}>
                        <img src={item.url} alt={item.altText} />
                    </div>
                )}
            </Carousel>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
        </div>
    );
}

export default StaticPage;