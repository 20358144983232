import "react-responsive-carousel/lib/styles/carousel.min.css";

import './FeedItem.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Slideshow from '../Slideshow/Slideshow';

function FeedItem({ className, title, description, media }) {
    return (<div className={`feed-item ${className || ""}`}>
        <Slideshow media={media} formatToUse="smallUrl" showArrows={false} />
        <div className='feed-item-content'>
            <h2>{title}</h2>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
        </div>
    </div>);
}

export default FeedItem;
