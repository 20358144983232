import "react-responsive-carousel/lib/styles/carousel.min.css";

import './CollectionItemDisplay.css';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Slideshow from '../../components/Slideshow/Slideshow';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import WebsiteInfoContext from '../../contexts/WebsiteInfoContext';
import { get } from '../../utils/fetch-utils';
import { CollectionItem } from '../../model/CollectionItem';

function CollectionItemDisplay({ title, singleItemUrlTemplate }) {
    const { id } = useParams();
    const [item, setItem] = useState({in:()=>{return {}}});
    const { language, websiteInfo } = useContext(WebsiteInfoContext);
    useEffect(() => {
        document.title = `${websiteInfo.in(language).title} - ${title}`;
    }, [title, language, websiteInfo]);
    useEffect(() => {
        const getData = async () => {
            const itemInformation = await get(`${singleItemUrlTemplate.replaceAll("{{:id}}", `${id}`)}`);
            const _item = new CollectionItem({"id": itemInformation.data.id, ...itemInformation.data.attributes});
            setItem(_item);
        };
        getData();
    }, [singleItemUrlTemplate, id]);

    return (<div className="collection-item-display">
        {item.in(language).title}
        <Slideshow media={item.in(language).media} formatToUse="url" showArrows={false} />
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.in(language).description}</ReactMarkdown>
    </div>);
}

export default CollectionItemDisplay;
