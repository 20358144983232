import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import config from '../config.json';
import CollectionPage from '../page-components/CollectionPage/CollectionPage';
import StaticPage from '../page-components/StaticPage/StaticPage';
import Page from '../model/Page';
import { WebsiteInfo } from '../model/WebsiteInfo';
import { get } from '../utils/fetch-utils';

import './App.css';
import 'react-responsive-carousel/lib/styles/carousel.css';
import Layout from '../page-components/Layout/Layout';
import WebsiteInfoContext from '../contexts/WebsiteInfoContext';
import Social from '../model/Social';
import CollectionItemDisplay from '../page-components/CollectionItemDisplay/CollectionItemDisplay';

function App() {
    const urlLang = window.location.hash.slice(1);
    const [websiteInfo, setWebsiteInfo] = useState();
    const [pages, setPages] = useState([]);
    const [language, setLanguage] = useState(urlLang || "en");
    const [languages, setLanguages] = useState([urlLang || "en"]);
    const [socials, setSocials] = useState([]);
    const context = {
        websiteInfo,
        setWebsiteInfo,
        pages,
        setPages,
        languages,
        setLanguages,
        language,
        setLanguage,
        socials,
        setSocials
    };


    useEffect(() => {
        const getWebsiteData = async () => {
            const websiteInformation = await get(`${config.url}/api/website-info?populate=localizations&populate=logoSmall&populate=logoBig`);
            const _websiteInfo = new WebsiteInfo(websiteInformation.data.attributes);
            setLanguages(Object.keys(_websiteInfo.lang));
            setWebsiteInfo(_websiteInfo);
            const faviconElem = document.getElementById("favicon");
            faviconElem.href = _websiteInfo.in(Object.keys(_websiteInfo.lang)[0]).logoSmall.url;
            faviconElem.type = _websiteInfo.in(Object.keys(_websiteInfo.lang)[0]).logoSmall.mime;
        };
        getWebsiteData();
    }, []);
    useEffect(() => {
        const getPageData = async () => {
            const pageInformation = await get(`${config.url}/api/pages?populate=localizations&populate=media`);
            const _pages = pageInformation.data.map(page => new Page(page.attributes));
            setPages([..._pages].sort((a, b) => a.order - b.order));
        };
        getPageData();
    }, [language]);
    useEffect(() => {
        const getSocialData = async () => {
            const socialInformation = await get(`${config.url}/api/socials`);
            setSocials(socialInformation.data.map(social => new Social(social.attributes)));
        };
        getSocialData();
    }, []);

    if (!websiteInfo || !pages) {
        return (
            <div className="App">
                Loading...
            </div>
        );
    }

    return (
        <WebsiteInfoContext.Provider value={context}>
            <Router>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        {!pages[0] ? null : <Route path="" element={<StaticPage {...pages[0].in(language)} />} />}
                        {pages.slice(1).map(page => {
                            if (page.isCollection) {
                                return <Route key={`route-${page.link}`} path={`${page.link}/`} element={<CollectionPage {...page.in(language)} />} />;
                            }
                            return <Route key={`route-${page.link}`} path={page.link} element={<StaticPage {...page.in(language)} />} />;
                        })}
                        
                        {pages.slice(1).filter(page => page.isCollection).map(page => {
                            return <Route key={`route-${page.link}/:id`} path={`${page.link}/:id`} element={<CollectionItemDisplay {...page.in(language)} />} />
                        })}
                    </Route>
                </Routes>
            </Router>
        </WebsiteInfoContext.Provider>
    );
}

export default App;
