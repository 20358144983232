import { toHaveDisplayValue } from '@testing-library/jest-dom/dist/matchers';
import config from '../config.json';
import { toLink } from '../utils/string-utils';
import Media from './Media';

class LocalisedPage {
    constructor(attributes) {
        this.title = attributes.title;
        this.order = attributes.order;
        this.content = attributes.content;
        this.isCollection = attributes.isCollection;
        this.collectionUrl = attributes.collectionUrl;
        this.singleItemUrlTemplate = attributes.singleItemUrlTemplate;
        this.collectionStyle = attributes.collectionStyle;
        this.media = !this.isCollection ? (attributes.media.data || []).map(item => new Media(item.attributes)) : [];
    }
}

class Page {
    constructor(attributes) {
        this.order = attributes.order;
        this.isCollection = !!attributes.dynamicCollection;
        this.collectionStyle = this.isCollection ? attributes.dynamicCollectionStyle : null;
        this.collectionUrl = this.isCollection ? `${config.url}/api/${attributes.dynamicCollection}?populate=localizations&populate=media` : null;
        this.singleItemUrlTemplate = this.isCollection ? `${config.url}/api/${attributes.dynamicCollection}/{{:id}}?populate=localizations&populate=media` : null;
        this.link = toLink(attributes.title);
        this.lang = {
            [attributes.locale]: new LocalisedPage({
                ...attributes,
                "isCollection": this.isCollection,
                "collectionUrl": this.collectionUrl,
                "singleItemUrlTemplate": this.singleItemUrlTemplate,
                "collectionStyle": this.collectionStyle
            }),
        };
        attributes.localizations.data.forEach((localization => {
            this.lang[localization.attributes.locale] = new LocalisedPage({
                ...localization.attributes,
                "media": attributes.media,
                "isCollection": this.isCollection,
                "collectionUrl": this.collectionUrl,
                "collectionStyle": this.collectionStyle
            });
        }));
    }

    in(language) {
        return this.lang[language] || Object.values(this.lang)[0];
    }
}

export default Page;